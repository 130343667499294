import React from 'react'
import { Link } from 'gatsby'
import Icon from '../../assets/svg/arquetipo-icon.svg'

const MenuBlock = ({ menuBlockClasses }) => (
  <div id='menu-block' className={menuBlockClasses}>
    <nav>
      <ul>
        <li><Link to='/'>Home</Link></li>
        <li><Link to='/about'>About</Link></li>
        <li><Link to='/projects'>Projects</Link></li>
        <li><Link to='/contact'>Contact</Link></li>
      </ul>
    </nav>
    <div className='press-section'>
      <div>
        <p className='press'>Press</p>
        <p>For press inquiries:</p>
        <p><a href='mailto:press@arquetipoestudio.com'>press@arquetipoestudio.com</a></p>
      </div>
      <Icon/>
    </div>
  </div>
)

export default MenuBlock
