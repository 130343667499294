import React from 'react'
import { Link } from 'gatsby'
import Icon from '../../assets/svg/arquetipo-icon.svg'

const Footer = () => {
  const addClass = () => {
    const wrapper = document.body
    wrapper.classList.toggle('dark-theme')
  }
  return(
    <footer className='grid'>
      <Icon onClick={addClass}/>
      <div className='address'>
        <p><a href="https://goo.gl/maps/msgT1fHALyEHFsCJA" target="__blank">Zamora 36 - 4</a></p>
        <p><a href="https://goo.gl/maps/msgT1fHALyEHFsCJA" target="__blank">Colonia Condesa, CDMX.</a></p>
      </div>
      <div className='telephone'>
        <Link to='/contact'>Contact</Link>
        <p><a href="tel:+52 55 6286 8642">+(52) 55 6286 8642</a></p>
      </div>
      <div className='social-media'>
        <p><a href='https://www.instagram.com/arquetipo_estudio' target='__blank'>Instagram</a></p>
        <Link to='/privacy-policy'>Privacy Policy</Link>
      </div>
      <div className='rights'>
        <p>Copyright © 2020 Arquetipo.</p>
        <p><a href="https://latentestudio.com/?ref=arquetipo" target="__blank">Site by LATENTE</a></p>
      </div>
    </footer>
  )
}

export default Footer
