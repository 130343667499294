import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import MenuBlock from './menuBlock'

const Menu = () => {
  const [visible, setVisible] = useState(false)
  let
    menuClasses = classNames({
      'menu open': visible === true,
      'menu': visible === false
    }),
    menuBlockClasses = classNames({
      'open': visible === true,
      'close': visible === false
    })

  useEffect(() => {
    if (!!visible){
      document.body.classList.add('overflow-none')
    } else {
      document.body.classList.remove('overflow-none')
    }
  })

  return (
    <React.Fragment>
      <div className={menuClasses} onClick={() => setVisible(!visible)}>
        <span className='menu-icon'></span>
        <span className='menu-icon'></span>
      </div>

      <MenuBlock menuBlockClasses={menuBlockClasses}/>
    </React.Fragment>
  )
}

export default Menu
